import React, { FC, useEffect, useState } from "react";
import { Navigate, Route, Routes as Switch, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MfaForm } from "./pages/MfaForm/MfaForm";
import { AppDispatch } from "./store";
import { userActions } from "./slices/userSlice";
import { passwordFormModes } from "./pages/PasswordForm/modes";
import { LoginForm } from "./pages/LoginForm/LoginForm";
import { RegisterForm } from "./pages/RegisterForm/RegisterForm";
import { PasswordForm } from "./pages/PasswordForm/PasswordForm";
import { ConfirmLogin } from "./pages/ConfirmLogin/ConfirmLogin";
import { ChangeLogin } from "./pages/ChangeLogin/ChangeLogin";
import { ResetPasswordInit } from "./pages/ResetPasswordInit/ResetPasswordInit";
import { RemindLoginInit } from "./pages/RemindLoginInit/RemindLoginInit";
import { RemindLoginSubmit } from "./pages/RemindLoginSubmit/RemindLoginSubmit";
import { Logout } from "./pages/Logout/Logout";
import { Cookies } from "./pages/Cookies/Cookies";
import { Services } from "./services/Services";
import { OneTab } from "./OneTab";
import { RedirectToCP } from "./pages/RedirectToCP/RedirectToCP";
import { DocumentPasswordForm } from "./pages/DocumentPasswordForm/DocumentPasswordForm";

enum Statuses {
  initializing = "initializing",
  authenticated = "authenticated",
  notAuthenticated = "notAuthenticated",
  missingCookie = "missingCookie",
}

export const Routes: FC = () => {
  const [status, setStatus] = useState("initializing" as Statuses);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    (async () => {
      if (!document.cookie.includes("cookiesAccepted=true")) {
        navigate("/cookies");
        setStatus(Statuses.missingCookie);
        return;
      }

      try {
        const userInfo = await Services.User.getUserInfo().then((data) => data.json());
        dispatch(userActions.setUserInfo(userInfo));

        await Services.User.init(); // this call causes redirect to /mfa route in case of device not trusted
        setStatus(Statuses.authenticated);
      } catch {
        setStatus(Statuses.notAuthenticated);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticatedUserRoutes = (
    <>
      <Route path="/" element={<RedirectToCP />} />
      <Route path="/change-login" element={<ChangeLogin />} />
      <Route path="/change-password" element={<PasswordForm mode={passwordFormModes.change} />} />
      <Route path="/confirm-login" element={<ConfirmLogin />} />
      <Route
        path="/create-document-password"
        element={<DocumentPasswordForm mode={passwordFormModes.documentCreate} />}
      />
      <Route
        path="/change-document-password"
        element={<DocumentPasswordForm mode={passwordFormModes.documentChange} />}
      />
      <Route path="/" element={<Navigate to="/" replace />} />
    </>
  );

  const notAuthenticatedUserRoutes = (
    <>
      <Route path="/" element={<LoginForm />} />
      <Route path="/remind-login-final" element={<LoginForm />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/set-password" element={<PasswordForm mode={passwordFormModes.set} />} />
      <Route path="/remind-login-init" element={<RemindLoginInit />} />
      <Route path="/remind-login-submit" element={<RemindLoginSubmit />} />
      <Route path="/reset-password-init" element={<ResetPasswordInit />} />
      <Route
        path="/reset-password-submit"
        element={<PasswordForm mode={passwordFormModes.reset} />}
      />
      <Route path="/mfa" element={<MfaForm />} />
      <Route path="/" element={<Navigate to="/" replace />} />
    </>
  );

  return (
    <OneTab>
      <Switch>
        <Route path="/logout" element={<Logout />} />
        <Route path="/cookies" element={<Cookies />} />
        {status === Statuses.authenticated ? authenticatedUserRoutes : notAuthenticatedUserRoutes}
      </Switch>
    </OneTab>
  );
};
