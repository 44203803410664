import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Services } from "../services/Services";
import { history, pushToHistory, replaceHistory } from "../history";
import { loadingStatuses } from "../constants/loadingStatuses";
import {
  LoginChangeInitData,
  LoginChangeConfirmData,
  LoginData,
  LoginRemindConfirmData,
  LoginRemindInitData,
  PasswordResetConfirmData,
  PasswordResetInitData,
  RegisterConfirmData,
  RegisterInitData,
  UserInfoDto,
  ActivateSessionConfirmData,
  DocumentPasswordConfirmData,
} from "../models/ApiModels";
import { passwordFormModes } from "../pages/PasswordForm/modes";

const initialState = {
  loadingStatus: loadingStatuses.idle,
  errorTrKey: "",
  name: "",
  formData: {
    username: "",
    password: "",
    policyNo: "",
    pid: "",
    authorizationCode: "",
    newPassword: "",
    newUsername: "",
  },
};

export type UserFormData = typeof initialState.formData;

const userThunks = {
  login: createAsyncThunk("user/login", async (data: LoginData, thunkAPI) => {
    await Services.User.login(data);
    await thunkAPI.dispatch(userThunks.getUserInfo());
    await Services.User.init(); // check session active
    window.location.href = "/cp";
  }),

  logout: createAsyncThunk("user/logout", async () => {
    await Services.User.logout();
    window.location.href = "/home/logout";
  }),

  getUserInfo: createAsyncThunk("user/getUserInfo", async (data, thunkAPI) => {
    const userInfo = await Services.User.getUserInfo().then((data) => data.json());
    thunkAPI.dispatch(userSlice.actions.setUserInfo(userInfo));
  }),

  registerInit: createAsyncThunk(
    "user/registerInit",
    async (data: RegisterInitData | undefined, thunkAPI: any) => {
      const appState = thunkAPI.getState();
      const preparedData = data || {
        policyNo: appState?.user.formData.policyNo,
        pid: appState?.user.formData.pid,
      };

      await Services.User.registerInit(preparedData);

      if (window.location.pathname !== "/home/set-password") {
        pushToHistory("/set-password");
      }
    }
  ),

  registerConfirm: createAsyncThunk(
    "user/registerConfirm",
    async (data: RegisterConfirmData, thunkAPI) => {
      await Services.User.registerConfirm(data);
      await thunkAPI.dispatch(userThunks.getUserInfo());
      window.location.href = "/home/confirm-login";
    }
  ),

  documentPasswordInit: createAsyncThunk("user/documentPasswordInit", async () => {
    await Services.Document.passwordCreateInit();
  }),

  documentPasswordConfirm: createAsyncThunk(
    "user/documentPasswordConfirm",
    async (
      { data, mode }: { data: DocumentPasswordConfirmData; mode: passwordFormModes },
      thunkAPI
    ) => {
      thunkAPI.dispatch(userSlice.actions.setAuthorizationCode(data.authToken));
      await Services.Document.passwordCreateConfirm(data);
      thunkAPI.dispatch(userSlice.actions.setAuthorizationCode(""));
      window.location.href = `/cp/profile?document-password-save-result=${
        mode === passwordFormModes.documentCreate ? "create" : "change"
      }`;
    }
  ),

  passwordResetInit: createAsyncThunk(
    "user/passwordResetInit",
    async (data: PasswordResetInitData, thunkAPI: any) => {
      const excludedPaths = ["/home/change-password", "/home/reset-password-submit"];
      let { username } = data;

      if (!data.username) {
        await thunkAPI.dispatch(userThunks.getUserInfo());
        username = thunkAPI.getState()?.user.formData.username;
      }

      await Services.User.passwordResetInit({ username });

      if (!excludedPaths.includes(window.location.pathname)) {
        pushToHistory("/reset-password-submit");
      }
    }
  ),

  passwordResetConfirm: createAsyncThunk(
    "user/passwordResetConfirm",
    async (data: PasswordResetConfirmData, thunkAPI: any) => {
      let preparedData = { ...data };

      if (!data.username) {
        await thunkAPI.dispatch(userThunks.getUserInfo());
        preparedData.username = thunkAPI.getState()?.user.formData.username;
      }

      await Services.User.passwordResetConfirm(preparedData);

      if (window.location.pathname === "/home/change-password") {
        window.location.href = "/cp/profile";
      } else {
        await Services.User.login({
          username: data.username,
          password: data.newPassword,
        });
        window.location.href = "/cp";
      }
    }
  ),

  loginChangeInit: createAsyncThunk(
    "user/loginChangeInit",
    async (data: LoginChangeInitData, thunkAPI: any) => {
      let { username } = data;
      if (!data.username) {
        await thunkAPI.dispatch(userThunks.getUserInfo());
        username = thunkAPI.getState()?.formData.username;
      }

      await Services.User.loginChangeInit({ username });

      if (window.location.pathname !== "/home/change-login") {
        pushToHistory("/change-login", { state: { from: history.location.pathname } });
      }
    }
  ),

  loginChangeConfirm: createAsyncThunk(
    "user/loginChangeConfirm",
    async (data: LoginChangeConfirmData, thunkAPI: any) => {
      const historyState = history.location?.state as { from: string } | undefined;
      let preparedData = { ...data };

      if (!data.username) {
        await thunkAPI.dispatch(userThunks.getUserInfo());
        preparedData.username = thunkAPI.getState()?.user.formData.username;
      }

      await Services.User.loginChangeConfirm(preparedData);

      replaceHistory(history.location.pathname, { state: {} });
      window.location.href = historyState?.from === "/confirm-login" ? "/cp" : "/cp/profile";
    }
  ),

  loginRemindInit: createAsyncThunk("user/loginRemindInit", async (data: LoginRemindInitData) => {
    await Services.User.loginRemindInit(data);
    if (window.location.pathname !== "/home/remind-login-submit") {
      pushToHistory("/remind-login-submit");
    }
  }),

  loginRemindConfirm: createAsyncThunk(
    "user/loginRemindConfirm",
    async (data: LoginRemindConfirmData, thunkAPI) => {
      const { username } = await Services.User.loginRemindConfirm(data).then((data) => data.json());
      thunkAPI.dispatch(
        userSlice.actions.setUserInfo({
          username,
          name: "",
        })
      );
      pushToHistory("/remind-login-final");
    }
  ),

  dropActivateSession: createAsyncThunk("user/dropActivateSession", async () => {
    await Services.User.logout();
    window.location.href = "/home";
  }),

  activateSessionInit: createAsyncThunk("user/activateSessionInit", async () => {
    await Services.User.activateSessionInit();
  }),

  activateSessionConfirm: createAsyncThunk(
    "user/activateSessionConfirm",
    async (data: ActivateSessionConfirmData) => {
      await Services.User.activateSessionConfirm(data);
      window.location.href = "/cp";
    }
  ),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfoDto>) => {
      state.name = action.payload.name;
      state.formData.username = action.payload.username;
    },
    setAuthorizationCode: (state, action: PayloadAction<string>) => {
      state.formData = {
        ...state.formData,
        authorizationCode: action.payload,
      };
    },
    dumpState: () => initialState,
  },
  extraReducers: (builder) => {
    Object.keys(userThunks).forEach((thunk) => {
      const selectedThunk = userThunks[thunk as keyof typeof userThunks];

      builder.addCase(selectedThunk.pending, (state, action: any) => {
        state.loadingStatus = loadingStatuses.pending;
        state.errorTrKey = "";
        state.formData = {
          ...state.formData,
          ...action.meta.arg,
        };
      });

      builder.addCase(selectedThunk.fulfilled, (state) => {
        state.loadingStatus = loadingStatuses.succeeded;
      });

      builder.addCase(selectedThunk.rejected, (state, action: any) => {
        state.loadingStatus = loadingStatuses.failed;
        state.errorTrKey = action.error.message ? action.error.message : "";
      });
    });
  },
});

export const userReducer = userSlice.reducer;
export const userActions = {
  ...userSlice.actions,
  ...userThunks,
};
