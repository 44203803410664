import { useState } from "react";

export type ValidationSchema<T> = {
  [fieldId in keyof T]: {
    errorTrKey: string;
    validate: (value: string, allValues: T) => boolean;
    runEventName: "change" | "blur";
  }[];
};

export const useValidation = <T>(schema: ValidationSchema<T>) => {
  const validationSchema = schema;
  const [errorMessages, setErrorMessages] = useState<{ [fieldId in keyof T]?: string }>();
  const validateField = (fieldId: keyof T, value: string, allValues: T) => {
    let isAllValidationsPass = true;

    validationSchema[fieldId].forEach(({ errorTrKey, validate }) => {
      const isCurrentValidationPass = validate(value, allValues);

      setErrorMessages((prevErrors) => {
        if (!prevErrors?.[fieldId]) {
          if (isCurrentValidationPass) return prevErrors;
          return { ...prevErrors, [fieldId]: errorTrKey };
        }

        if (isCurrentValidationPass) {
          const currentErrorTrKey = prevErrors[fieldId];
          return {
            ...prevErrors,
            [fieldId]: currentErrorTrKey === errorTrKey ? undefined : currentErrorTrKey,
          };
        }

        return prevErrors;
      });

      if (!isCurrentValidationPass) isAllValidationsPass = false;
    });

    return isAllValidationsPass;
  };

  return { errorMessages, validateField };
};
