import { userActions, UserFormData } from "../../slices/userSlice";
import { passwordFormModes } from "./modes";

export const modesData = {
  [passwordFormModes.set]: {
    titleTrKey: "registerForm.title",
    formTitleTrKey: "",
    dispatchAction: userActions.registerConfirm,
    store: "setPassword",
    sendAuthMessageAgainAction: (formData: UserFormData) => {
      const { policyNo, pid } = formData;
      return userActions.registerInit({ policyNo, pid });
    },
  },
  [passwordFormModes.change]: {
    titleTrKey: "changePasswordForm.title",
    formTitleTrKey: "",
    dispatchAction: userActions.passwordResetConfirm,
    store: "changePassword",
    sendAuthMessageAgainAction: (formData: UserFormData) =>
      userActions.passwordResetInit({ username: formData.username }),
  },
  [passwordFormModes.reset]: {
    titleTrKey: "resetPasswordForm.title",
    formTitleTrKey: "",
    dispatchAction: userActions.passwordResetConfirm,
    store: "resetPasswordConfirm",
    sendAuthMessageAgainAction: (formData: UserFormData) =>
      userActions.passwordResetInit({ username: formData.username }),
  },
  [passwordFormModes.documentCreate]: {
    titleTrKey: "documentPassword.create.title",
    formTitleTrKey: "documentPassword.create.form.title",
    dispatchAction: userActions.documentPasswordConfirm,
    sendAuthMessageAgainAction: () => userActions.documentPasswordInit(),
  },
  [passwordFormModes.documentChange]: {
    titleTrKey: "documentPassword.change.title",
    formTitleTrKey: "documentPassword.change.form.title",
    dispatchAction: userActions.documentPasswordConfirm,
    sendAuthMessageAgainAction: () => userActions.documentPasswordInit(),
  },
};

export const newPasswordValidationConditions = [
  {
    labelTrKey: "passwordForm.validatorConditions.min12Max50Char",
    testFn: (phrase: string) => phrase.length >= 12 && phrase.length <= 50,
  },
  {
    labelTrKey: "passwordForm.validatorConditions.minOneUppercase",
    testFn: (phrase: string) => /[A-ZĘÓĄŚŁŻŹĆŃ]/.test(phrase),
  },
  {
    labelTrKey: "passwordForm.validatorConditions.minOneDigit",
    testFn: (phrase: string) => /\d/.test(phrase),
  },
  {
    labelTrKey: "passwordForm.validatorConditions.minOneSpecial",
    testFn: (phrase: string) => /[-!$%^&*()"'/\\_+|~=`{}[:;<>?,.@#\]]/g.test(phrase),
  },
];

export const checkAllowedCharsFn = (phrase: string) =>
  /^[a-z\d\-!$%^&*()"'/\\_+|~=`{}[:;<>?,.@#\]]*$/gi.test(phrase);
