import React from "react";
import styled from "styled-components";
import { t } from "../../intl";

const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";

export const AllowedCharsModalContent = () => (
  <Wrapper>
    <p>{t("documentPassword.validation.modal.allowedChars.title")}</p>
    <ul>
      <li>{t("documentPassword.validation.modal.allowedChars.numbers")}</li>
      <li>{t("documentPassword.validation.modal.allowedChars.letters")}</li>
      <li>{t("documentPassword.validation.modal.allowedChars.specialChars", { specialChars })}</li>
    </ul>
  </Wrapper>
);

const Wrapper = styled.div`
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray100};

  p,
  ul {
    margin: 0;
  }

  ul {
    padding-left: 30px;
  }
`;
