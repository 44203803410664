import { ApiService } from "./ApiService";
import {
  ActivateSessionConfirmData,
  LoginChangeConfirmData,
  LoginChangeInitData,
  LoginData,
  LoginRemindConfirmData,
  LoginRemindInitData,
  PasswordResetConfirmData,
  PasswordResetInitData,
  RegisterConfirmData,
  RegisterInitData,
} from "../models/ApiModels";
import { userActions } from "../slices/userSlice";

export class UserService extends ApiService {
  private logoutInExecution: boolean = false;

  protected retrySendingAuthorizationCode = () => {
    return this.dispatch!(userActions.registerInit(undefined));
  };

  public login = (data: LoginData) => this.post("auth/login", data);

  public init = () => this.post("user/init", {});

  public logout = async () => {
    if (!this.logoutInExecution) {
      try {
        this.logoutInExecution = true;
        await this.delete("session");
      } finally {
        this.logoutInExecution = false;
      }
    }
  };

  public getUserInfo = () => this.get("user/info");

  public registerInit = (data: RegisterInitData) => this.post("user/register/init", data);

  public registerConfirm = (data: RegisterConfirmData) => this.post("user/register/confirm", data);

  public passwordResetInit = (data: PasswordResetInitData) => {
    const { username } = data;
    return this.post(`user/${encodeURI(username)}/password-reset/init`, {});
  };

  public passwordResetConfirm = (data: PasswordResetConfirmData) => {
    const { username, newPassword, authorizationCode } = data;
    return this.post(`user/${encodeURI(username)}/password-reset/confirm`, {
      newPassword,
      authorizationCode,
    });
  };

  public loginChangeInit = (data: LoginChangeInitData) => {
    const { username } = data;
    return this.post(`user/${encodeURI(username)}/login-change/init`, {});
  };

  public loginChangeConfirm = (data: LoginChangeConfirmData) => {
    const { username, newUsername, authorizationCode } = data;
    return this.post(`user/${encodeURI(username)}/login-change/confirm`, {
      newUsername,
      authorizationCode,
    });
  };

  public loginRemindInit = (data: LoginRemindInitData) => {
    return this.post(`user/login-remind/init`, data);
  };

  public loginRemindConfirm = (data: LoginRemindConfirmData) => {
    return this.post(`user/login-remind/confirm`, data);
  };

  public activateSessionInit = () => {
    return this.post(`user/activate-session/init`, {});
  };

  public activateSessionConfirm = (data: ActivateSessionConfirmData) => {
    return this.post(`user/activate-session/confirm`, data);
  };
}
