import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { AppDispatch, DefaultRootState } from "../../store";
import { ButtonsContainer, ErrorMessage, Title } from "../../components/UIComponents";
import { AuthorizationCodeInfo } from "../../components/AuthorizationCodeInfo/AuthorizationCodeInfo";
import { Validator } from "../../components/UIComponents/Validator";
import { loadingStatuses } from "../../constants/loadingStatuses";
import { localErrorsMessages } from "../../constants/errors";
import SendSmsAgainButton from "../../components/SendSmsAgainButton/SendSmsAgainButton";
import { FormWrapperWide, ShadowedBox } from "../../components/UIComponents/styled";
import Phone from "../../assets/phone.svg";
import { HeaderImage } from "../../components/UIComponents/HeaderImage";
import { InputFormElement } from "../../components/UIComponents/InputFormElement";
import { CommonAsyncThunkAction, passwordFormModes } from "../PasswordForm/modes";
import {
  checkAllowedCharsFn,
  modesData,
  newPasswordValidationConditions,
} from "../PasswordForm/consts";
import { modalsActions } from "../../slices/modalsSlice";
import { AllowedCharsModalContent } from "./AllowedCharsModalContent";
import { useValidation, ValidationSchema } from "../../utils/useValidation";
import { getObjectKeys } from "../../utils/object";

type FieldsValue = {
  authorizationCode: string;
  newPassword: string;
  confirmedNewPassword: string;
};
type FieldId = keyof FieldsValue;

export const DocumentPasswordForm: React.FC<{ mode: passwordFormModes }> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingStatus, errorTrKey, formData } = useSelector(
    (state: DefaultRootState) => state.user
  );
  const [fieldsValue, setFieldsValue] = useState<FieldsValue>({
    authorizationCode: "",
    newPassword: "",
    confirmedNewPassword: "",
  });
  const [isDirtyField, setIsDirtyField] = useState<Record<FieldId, boolean>>({
    authorizationCode: false,
    newPassword: false,
    confirmedNewPassword: false,
  });
  const onFieldValueChange = (fieldId: FieldId, relatedFieldId?: FieldId) => (value: string) => {
    setFieldsValue((prev) => ({ ...prev, [fieldId]: value }));

    const updatedFieldsValue = { ...fieldsValue, [fieldId]: value };

    validationSchema[fieldId]
      .filter(({ runEventName }) => runEventName === "change")
      .forEach(() => validateField(fieldId, value, updatedFieldsValue));
    if (relatedFieldId && isDirtyField[relatedFieldId]) {
      validationSchema[relatedFieldId].forEach(() =>
        validateField(relatedFieldId, fieldsValue[relatedFieldId], updatedFieldsValue)
      );
    }
  };
  const onFieldValueBlur = (fieldId: FieldId) => (value: string) => {
    const updatedFieldsValue = { ...fieldsValue, [fieldId]: value };

    validationSchema[fieldId]
      .filter(({ runEventName }) => runEventName === "blur")
      .forEach(() => validateField(fieldId, value, updatedFieldsValue));
    setIsDirtyField((prev) => ({ ...prev, [fieldId]: true }));
  };
  const [isPasswordSufficient, setIsPasswordSufficient] = useState(false);
  const [isResendSmsLoaderVisible, setIsResendSmsLoaderVisible] = useState(false);
  const showAllowedCharsModal = () =>
    dispatch(
      modalsActions.show({
        Component: AllowedCharsModalContent,
        buttons: {
          confirm: { textTrKey: "button.ok" },
        },
      })
    );

  const navigate = useNavigate();
  const selectedMode = modesData[props.mode];

  const checkIsAuthorizationCodeValid = (value: string) =>
    errorTrKey !== localErrorsMessages["007"] || formData.authorizationCode !== value;

  const isAuthorizationCodeValid = checkIsAuthorizationCodeValid(fieldsValue.authorizationCode);
  const validationSchema: ValidationSchema<FieldsValue> = useMemo(
    () => ({
      authorizationCode: [
        {
          errorTrKey: "validation.rules.required",
          validate: (value) => value !== "",
          runEventName: "blur",
        },
        {
          errorTrKey,
          validate: checkIsAuthorizationCodeValid,
          runEventName: "change",
        },
      ],
      newPassword: [
        {
          errorTrKey: "validation.rules.required",
          validate: (value) => value !== "",
          runEventName: "blur",
        },
        {
          errorTrKey: "documentPassword.validation.invalidChar",
          validate: checkAllowedCharsFn,
          runEventName: "change",
        },
        {
          errorTrKey,
          validate: () => errorTrKey !== localErrorsMessages["013"],
          runEventName: "change",
        },
      ],
      confirmedNewPassword: [
        {
          errorTrKey: "validation.rules.required",
          validate: (value) => value !== "",
          runEventName: "blur",
        },
        {
          errorTrKey: "documentPassword.validation.passwordsNotMatch",
          validate: (value, allValues) => value === "" || value === allValues.newPassword,
          runEventName: "blur",
        },
      ],
    }),
    [errorTrKey]
  );
  const { errorMessages, validateField } = useValidation(validationSchema);
  const getValidationMessagesTrKeys = (fieldId: FieldId) => {
    return errorMessages?.[fieldId] ? [{ trKey: errorMessages[fieldId] }] : undefined;
  };

  useEffect(() => {
    if (errorTrKey !== localErrorsMessages["007"]) return;
    validateField("authorizationCode", fieldsValue.authorizationCode, fieldsValue);
  }, [errorTrKey]);

  useEffect(() => {
    if (errorTrKey !== localErrorsMessages["013"]) return;
    validateField("newPassword", fieldsValue.newPassword, fieldsValue);
  }, [errorTrKey]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let isAllValidationsPass = true;

    getObjectKeys(validationSchema).forEach((fieldId) => {
      if (validateField(fieldId, fieldsValue[fieldId], fieldsValue)) return;
      isAllValidationsPass = false;
    });

    if (
      !isAllValidationsPass ||
      loadingStatus === loadingStatuses.pending ||
      !isPasswordSufficient
    ) {
      return;
    }

    let parameters: any = {};

    switch (props.mode) {
      case passwordFormModes.documentCreate:
      case passwordFormModes.documentChange:
        parameters = {
          data: { password: fieldsValue.newPassword, authToken: fieldsValue.authorizationCode },
          mode: props.mode,
        };
        break;
      case passwordFormModes.set:
        parameters = {
          authorizationCode: fieldsValue.authorizationCode,
          password: fieldsValue.newPassword,
          pid: formData.pid,
          policyNo: formData.policyNo,
        };
        break;
      default:
        parameters = {
          authorizationCode: fieldsValue.authorizationCode,
          newPassword: fieldsValue.newPassword,
          username: formData.username,
        };
        break;
    }

    dispatch(selectedMode.dispatchAction(parameters) as unknown as CommonAsyncThunkAction);
  };

  const resendAuthorizationCode = useCallback(() => {
    if (isResendSmsLoaderVisible) return;
    dispatch(
      selectedMode.sendAuthMessageAgainAction(formData) as unknown as CommonAsyncThunkAction
    );
    setIsResendSmsLoaderVisible(true);
    setTimeout(() => setIsResendSmsLoaderVisible(false), 1000);
  }, [isResendSmsLoaderVisible, dispatch, selectedMode, formData]);

  return (
    <FormWrapperWide onSubmit={onSubmit}>
      <Wrapper>
        <HeaderWrapper>
          <HeaderImage alt={"phone"} image={Phone} />
          <Title>{t(modesData[props.mode].titleTrKey)}</Title>
          <AuthorizationCodeInfo textCenter={true} />
        </HeaderWrapper>
        <ContainerBox width={823} padding={32}>
          <ItemBox>
            <ItemTitle>{t("registerForm.enterOneTimepassword")}</ItemTitle>
            <InputFormElement
              labelProps={{
                labelTrKey: "common.placeholders.authorizationCode",
              }}
              inputProps={{
                value: fieldsValue.authorizationCode,
                onChange: onFieldValueChange("authorizationCode"),
                onBlur: onFieldValueBlur("authorizationCode"),
                isInvalid: !!errorMessages?.authorizationCode,
                inputType: "password",
                inputMode: "numeric",
                allowedSignsRegex: "^\\d*$",
                validationMessagesTrKeys: getValidationMessagesTrKeys("authorizationCode"),
              }}
            />
            {!!isAuthorizationCodeValid && <SendSmsAgainButton onClick={resendAuthorizationCode} />}
          </ItemBox>
          <ItemBox>
            <ItemTitle>{t(modesData[props.mode].formTitleTrKey)}</ItemTitle>
            <InputFormElementsWrapper>
              <InputFormElement
                labelProps={{
                  labelTrKey: "passwordForm.newPasswordPlaceholder",
                }}
                inputProps={{
                  value: fieldsValue.newPassword,
                  onChange: onFieldValueChange("newPassword", "confirmedNewPassword"),
                  onBlur: onFieldValueBlur("newPassword"),
                  isInvalid: !!errorMessages?.newPassword,
                  inputType: "password",
                  validationMessagesTrKeys: getValidationMessagesTrKeys("newPassword"),
                  onValidationIconClick:
                    errorMessages?.newPassword === "documentPassword.validation.invalidChar"
                      ? showAllowedCharsModal
                      : undefined,
                }}
              />
              <InputFormElement
                labelProps={{
                  labelTrKey: "passwordForm.repeatNewPasswordPlaceholder",
                }}
                inputProps={{
                  value: fieldsValue.confirmedNewPassword,
                  onChange: onFieldValueChange("confirmedNewPassword", "newPassword"),
                  onBlur: onFieldValueBlur("confirmedNewPassword"),
                  isInvalid: !!errorMessages?.confirmedNewPassword,
                  inputType: "password",
                  validationMessagesTrKeys: getValidationMessagesTrKeys("confirmedNewPassword"),
                }}
              />
            </InputFormElementsWrapper>
            <Validator
              conditions={newPasswordValidationConditions}
              phrase={fieldsValue.newPassword}
              setIsPhraseSufficient={setIsPasswordSufficient}
              title={t("passwordForm.passwordConditionsTitle")}
            />
          </ItemBox>
        </ContainerBox>
        <ButtonsContainer
          justify={`center`}
          flatButton={{
            textTrKey: "button.back",
            onClick: () => navigate(-1),
          }}
          submitButton={{
            textTrKey: "documentPassword.button.save",
          }}
        />
      </Wrapper>
    </FormWrapperWide>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputFormElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 24px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-bottom: 17px;
  margin-top: 0;
`;

const ContainerBox = styled(ShadowedBox)`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => theme.media.belowMd} {
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.belowMd} {
    margin-bottom: 32px;

    &:first-child {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -19px;
        height: 1px;
        background-color: #e3e3e3;
      }
    }
  }

  ${({ theme }) => theme.media.aboveMd} {
    &:first-child {
      padding-right: 64px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        right: 32px;
        background-color: #e3e3e3;
      }

      &:before {
        content: "";
        height: 12px;
        width: 12px;
        border-top: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
        transform: rotate(45deg);
        position: absolute;
        right: 26px;
        top: 50%;
        z-index: 2;
        background-color: #fff;
      }
    }
  }
`;
