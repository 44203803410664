import { AsyncThunkAction } from "@reduxjs/toolkit";

export enum passwordFormModes {
  set = "set",
  change = "change",
  reset = "reset",
  documentCreate = "documentCreate",
  documentChange = "documentChange",
}

export type CommonAsyncThunkAction = AsyncThunkAction<void, any, {}>;
