import { DocumentPasswordConfirmData } from "../models/ApiModels";
import { userActions } from "../slices/userSlice";
import { cpApiUrl } from "./apiConstants";
import { ApiService } from "./ApiService";

export class DocumentService extends ApiService {
  constructor() {
    super(cpApiUrl);
  }

  protected retrySendingAuthorizationCode = () => {
    return this.dispatch!(userActions.documentPasswordInit());
  };

  public passwordCreateInit = () => {
    return this.put("doc-pwd/create/init", JSON.stringify({}));
  };

  public passwordCreateConfirm = (data: DocumentPasswordConfirmData) => {
    return this.put("doc-pwd/create/confirm", JSON.stringify(data));
  };
}
